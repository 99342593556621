import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatar',
  standalone: true,
})
export class AvatarPipe implements PipeTransform {
  transform(value: string): string {
    const first = value.split(' ')[0];
    const second = value.split(' ')[1];
    return first[0] + (second ? second[0] : '');
  }
}
